import { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AlertContext } from "../../App";
import AlertPopup from "../Alert/alert";

import "./index.css";

const NewPassword = () => {
    const [searchParams] = useSearchParams();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [error, setError] = useState("");

    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);

    const code = searchParams.get("code");

    const handleResetPassword = async () => {
        if (!password || !confirmPassword) {
            setError("Enter new password.");
            return;
        }
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const res = await fetch(
                process.env.REACT_APP_BASEURL + "/members/changepassword",
                {
                    method: "post",
                    body: JSON.stringify({
                        password,
                        code,
                    }),
                    headers: { "content-type": "application/json" },
                }
            );

            const data = await res.json();
            if (data?.success) {
                alertContext.show = true;
                alertContext.msg = "Password changed successfully!";
                alertContext.variant = "primary";
            }
        } catch (error) {
            alertContext.show = true;
            alertContext.msg = "Error resetting password";
            alertContext.variant = "danger";
        } finally {
            navigate("/signin");
        }
    };

    return (
        <div className="col-md-2 col-lg-4 mx-auto">
            {error && (
                <AlertPopup
                    message={error}
                    variant="danger"
                    setShow={() => setError(null)}
                />
            )}
            <h3 className="login-heading mb-4">Forgot Password</h3>
            {code && (
                <>
                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            className="form-control"
                            value={code}
                            readOnly={true}
                            disabled
                        />
                        <label htmlFor="email">Code</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            type="password"
                            className="form-control"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <label htmlFor="email">New Password</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                            type="password"
                            className="form-control"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <label htmlFor="email">Confirm Password</label>
                    </div>
                    <div className="d-grid">
                        <button
                            className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2"
                            style={{ fontSize: "16px" }}
                            onClick={handleResetPassword}
                            type="button">
                            Reset Password
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default NewPassword;
