import "./App.css";
import "./style.scss";
import Header from "./component/header/header";
import Footer from "./component/footer/footer";
import Signin from "./component/signin/signin";
import { Route, Routes, useNavigate } from "react-router";
import { createContext, useEffect, useState } from "react";
import Dashboard from "./component/Dashboard/dashboard";
import PrivateRoute from "./component/Privateroute/privateroute";
import Signup from "./component/Signup";
import ForgotPassword from "./component/ForgotPassword/forgotpassword";
import Terms from "./component/Terms/terms";
import Privacy from "./component/Privacy/privacy";
import NewPassword from "./component/NewPassword";
import AllPins from "./component/events/events";
import EventDetail from "./component/PinDetails/pindetails";
import TokenDetail from "./component/TokenDetail";
import Profile from "./component/Profile";
import NewEvent from "./component/NewEvent";
import ChangePassword from "./component/ChangePassword/changepass";
import EmailPins from "./component/EmailPins/emailpins";
import PinsTable from "./component/pinstable/pinstable";
import Collections from "./component/NewCollections/collection";
import Collectionlisting from "./component/CollectionListing/collectionlisting";
import TotalBilling from "./component/Billing";
import WaitList from "./component/Waitlist";
import EventWaitList from "./component/EventWaitlist";

export const AlertContext = createContext({
  show: false,
  msg: "",
  variant: "primary",
});

const Activate = () => {
  console.log("Activate start....");
  const navigate = useNavigate();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const code = params.get("code");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASEURL}/members/activate?code=${code}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        // Navigate to /signin and show a message "Test"
        if (data.activated === true) {
          navigate("/signin", {
            state: {
              message:
                "Congratulations! Your email has been verified. You can now log in to your account.",
            },
          });
        } else {
          console.log(data.reason);
          navigate("/signin", {
            state: { message: data.reason },
          });
        }
      });
  }, [code, navigate]);

  return <></>;
};

function App() {
  const [login, setLogin] = useState(false);
  const [popupmsg, setPopupmsg] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setLogin(true);
    }
  }, []);
  useEffect(() => {}, [login]);
  return (
    <div className="pages">
      <Header setmsg={setPopupmsg} login={login} setLogin={setLogin} />
      <div className="route container" style={{ padding: "2rem 0" }}>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Signin message={popupmsg} setLogin={setLogin} login={login} />
            }
          />
          <Route
            exact
            path="/signin"
            element={
              <Signin message={popupmsg} setLogin={setLogin} login={login} />
            }
          />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/activate" element={<Activate />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/newpassword" element={<NewPassword />} />
          <Route
            exact
            path="/user/changepassword"
            element={<ChangePassword />}
          />

          <Route element={<PrivateRoute />}>
            <Route path="/newevent" element={<NewEvent />} />
            <Route path="/events/:id" element={<EventDetail />} />
            <Route path="/events/email/:id" element={<EmailPins />} />
            <Route path="/billing" element={<TotalBilling />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/waitlist" element={<WaitList />} />
            <Route path="/eventwaitlist" element={<EventWaitList />} />
          </Route>

          <Route exact path="/tokens/:id" element={<TokenDetail />} />
          <Route exact path="/dashboard" element={<PrivateRoute />}>
            <Route path="" element={<Dashboard login={login} />} />
            <Route exact path="collection" element={<Collections />} />
            <Route
              exact
              path="Collectionlisting"
              element={<Collectionlisting />}
            />
          </Route>

          <Route path="/events" element={<AllPins />}>
            <Route path="" element={<PinsTable login={login} />} />
            <Route path="active" element={<PinsTable />} />
            <Route path="inactive" element={<PinsTable />} />
          </Route>
        </Routes>
      </div>

      <Footer />
    </div>
  );
}

export default App;
