import { useContext, useState } from "react";
import { AlertContext } from "../../App";
import { useNavigate } from "react-router";
import AlertPopup from "../Alert/alert";

const ForgotPassword = () => {
    const alertContext = useContext(AlertContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const [error, setError] = useState("");

    const handleForgotPassword = async () => {
        try {
            const res = await fetch(
                `${process.env.REACT_APP_BASEURL}/members/resetpassword?email=${encodeURIComponent(
                    email
                )}`,
                {
                    method: "GET",
                    headers: { "content-type": "application/json" },
                }
            );
            const data = await res.json();
            if (data["error"]) {
                setError(data["error"]);
            } else {
                alertContext.show = true;
                alertContext.msg =
                    "Password reset instructions were sent. Please check your email and continue.";
                navigate("/");
            }
        } catch (error) {
            setError("Server Error");
        }
    };

    return (
        <div
            className="mx-auto"
            style={{ maxWidth: "600px", padding: "0 2rem" }}>
            {error && (
                <AlertPopup
                    message={error}
                    variant="danger"
                    setShow={() => setError(null)}
                />
            )}
            <h3 className="login-heading mb-4">Forgot Password</h3>
            <form>
                <div className="form-floating mb-3">
                    <input
                        className="form-control"
                        type="email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="email">Email address</label>
                </div>
                <div className="d-grid">
                    <button
                        className="btn btn-lg btn-primary btn-login text-uppercase fw-bold"
                        style={{ fontSize: "16px" }}
                        type="button"
                        onClick={handleForgotPassword}>
                        Reset Password
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ForgotPassword;
