import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const Collectionlisting = () => {
  const navigate = useNavigate();
  const [collectionlist, setCollectionList] = useState();

  const collectionslist = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return navigate("/");
    }
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASEURL}/members/collections`,
        {
          headers: {
            Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );
      const data = await res.json();
      setCollectionList(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    collectionslist();
  }, [navigate]);
  const handleactivate = (e) => {
    const { id, name } = e.target;
    console.log(id, name);
    const user = JSON.parse(localStorage.getItem("user"));
    fetch(
      `${process.env.REACT_APP_BASEURL}/members/collections/${id}/${name}`,
      {
        headers: {
          Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => console.log(data))
      .finally(() => collectionslist());
  };
  return (
    <>
      <div className="container">
        <h2>Collections</h2>
        <div className="collection-header">
          <a href="/dashboard/collection"> Add Collection</a>
        </div>
        <div className="row">
          <div class="col-2">
            <b>ID</b>
          </div>
          <div class="col" style={{ textAlign: "center" }}>
            <b>Name</b>
          </div>
          <div class="col" style={{ textAlign: "center" }}>
            <b>Item</b>
          </div>
          <div class="col" style={{ textAlign: "center" }}>
            <b>Status</b>
          </div>
          <div class="col" style={{ textAlign: "center" }}>
            <b>Issued</b>
          </div>
          <div class="col" style={{ textAlign: "center" }}>
            <b>Action</b>
          </div>
        </div>
      </div>
      {collectionlist &&
        collectionlist.map((collection) => {
          return (
            <div className="row">
              <div
                class="col-2"
                style={{
                  verticalAlign: "top",
                }}
              >
                {/* <img
                  src={`https://asset.clifty.io/${collection.artifact.replace(
                    "ipfs://",
                    "ipfs/"
                  )}?pinataGatewayToken=Jj_K5bQqbTXNlJhh6FG9tf31rZP5XPWSGRGEf01BL4S9Ck8EZKABX6nZ-PwyNHf9`}
                  className="pin-image card-img-top mx-auto d-block"
                  alt={collection.name}
                /> */}
                <a
                  className="detail-link"
                  href={`/collections/${collection?.id}`}
                >
                  {collection.id}
                </a>
              </div>
              <div class="col" style={{ textAlign: "center" }}>
                {collection.name}
              </div>
              <div class="col" style={{ textAlign: "center" }}>
                {collection.maxlimit}
              </div>
              <div class="col" style={{ textAlign: "center" }}>
                {collection.active ? "Active" : "Inactive"}
              </div>
              <div style={{ textAlign: "center" }} class="col">
                {collection.evtype}
              </div>
              <div style={{ textAlign: "center" }} class="col">
                {collection.active === 0 ? (
                  <button
                    id={collection.id}
                    name="enable"
                    className="active-navlink"
                    onClick={(e) => handleactivate(e, collection.id)}
                    style={{ border: "none" }}
                  >
                    Enable
                  </button>
                ) : (
                  <button
                    id={collection.id}
                    name="disable"
                    onClick={(e) => handleactivate(e, collection.id)}
                    style={{ border: "none" }}
                  >
                    Disable
                  </button>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default Collectionlisting;
