import { useContext, useEffect, useState } from "react";
import "./pindetails.css";
import { useNavigate } from "react-router";
import { AlertContext } from "../../App";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";

const EventDetail = () => {
  const [event, setEvent] = useState();
  const [stats, setStats] = useState();
  const alertContext = useContext(AlertContext);
  const navigate = useNavigate();

  const localPinID = window.location.pathname.split("/")[2];

  useEffect(() => {
    const getEvent = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
        return navigate("/");
      }
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASEURL}/members/pins/${localPinID}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.sessionToken}`,
            },
          }
        );

        const data = await res.json();
        setEvent(data);
        console.log(JSON.stringify(JSON.parse(data.extra).attributes));
      } catch (err) {
        alertContext.show = true;
        alertContext.variant = "danger";
        if (err.toString()?.includes("Forbidden")) {
          alertContext.msg = "Session expired, please sign in";
          localStorage.removeItem("user");
          navigate("/");
        } else {
          alertContext.msg = "Error getting pins";
        }
      }
    };

    const getStats = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
        return navigate("/");
      }

      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASEURL}/members/stats/${localPinID}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.sessionToken}`,
            },
          }
        );
        const data = await res.json();
        setStats(data);
      } catch (err) {
        alertContext.show = true;
        alertContext.variant = "danger";
        if (err.toString()?.includes("Forbidden")) {
          alertContext.msg = "Session expired, please sign in";
          localStorage.removeItem("user");
          navigate("/");
        } else {
          alertContext.msg = "Error getting pins";
        }
      }
    };

    getEvent();
    getStats();
  }, [alertContext, localPinID, navigate]);

  const formatDatesTime = (date, userLocation) => {
    if (!date) {
      return;
    }
    let options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZoneName: "short",
    };
    let timeZone;

    if (userLocation === "India") {
      timeZone = "Asia/Kolkata";
    } else if (userLocation === "USA") {
      timeZone = "America/New_York";
    }

    // Use here
    const formattedDateTime = new Intl.DateTimeFormat("en-US", {
      ...options,
      timeZone: timeZone,
    }).format(new Date(date));

    return formattedDateTime;
  };

  return (
    <div className="eventDetail">
      <div>
        {event && stats && (
          <>
            <Link to="/events" style={{ textDecoration: "none" }}>
              &larr; Back
            </Link>
            <div align="center">
              <h1 style={{ textAlign: "center" }}>{event.name}</h1>
              <h4 style={{ fontSize: "14px", textAlign: "center" }}>
                {event.description}
              </h4>
              <br />
              <div>
                <img
                  src={`${event?.artifact?.replace(
                    "ipfs://",
                    "https://ipfs.io/ipfs/"
                  )}`}
                  alt=""
                  width={"40%"}
                  style={{ border: "1px solid silver" }}
                />
                <br />
              </div>
              <br />
              <div className="card" style={{ width: "36rem" }}>

                <div className="card-body">
                  <p className="card-text" style={{ fontSize: "16px" }}>
                    <b>Additional Properties</b>
                    <br />
                  </p>
                  <p>
                    {JSON.parse(event.extra).attributes.map((item) => {
                      const keyvalues = Object.values(item);
                      return (
                        <span>
                          <b>{keyvalues[0]}</b>:{keyvalues[1]}
                          <br />
                        </span>
                      );
                    })}
                  </p>
                </div>
              </div>
              <br />
              {(event.timestampfrom || event.timestampto) && (
                <>
                  <div className="card" style={{ width: "36rem" }}>
                    <div className="card-body">
                      <p className="card-text" style={{ fontSize: "16px" }}>
                        <b>Event Date</b>
                        <br />
                      </p>

                      <p>
                        {event.timestampfrom && (
                          <>
                            <b>Starts: </b>
                            {formatDatesTime(event.timestampfrom)}<br />
                          </>
                        )}
                        {event.timestampto && (
                          <>
                            <b>Ends: </b>
                            {formatDatesTime(event.timestampto)}<br />
                          </>
                        )}
                      </p>

                    </div>
                  </div>
                  <br />
                </>
              )}
              <div className="card" style={{ width: "36rem" }}>
                <div className="card-body">
                  <p className="card-text" style={{ fontSize: "16px" }}>
                    {event.mintnetwork}
                  </p>

                  {event.limitperperson > 0 && (
                    <p>
                      <b>Limit per person:</b> {event.limitperperson} <br />
                    </p>
                  )}
                  <p>
                    <b>Status:</b> {event.active ? "Active" : "Inactive"}
                  </p>

                  <p>
                    <b>Type:</b>{" "}
                    {event.evtype === "QR"
                      ? "Unique QR for everyone"
                      : event.evtype
                        .split(" ")
                        .map(
                          (word) =>
                            word[0].toUpperCase() +
                            word.slice(1).toLowerCase()
                        )
                        .join(" ")}
                  </p>

                  <p>
                    {event.evtype === "DIGITAL COLLECTIBLE" && (
                      <>
                        <b>QR Id: </b>
                        {event.qrid} <br />
                      </>
                    )}
                  </p>
                </div>
              </div>
              {event.active === 1 && (
                <>
                  <br />
                  <div className="card" style={{ width: "36rem" }}>
                    <div className="card-body">
                      <p className="card-text" style={{ fontSize: "16px" }}>
                        Distribution Link
                      </p>

                      <p>
                        {process.env.REACT_APP_PINSHAREURL +
                          "/qrs/" +
                          event.qrid}
                        <br /> <br />
                        <QRCode
                          value={
                            process.env.REACT_APP_PINSHAREURL +
                            "/qrs/" +
                            event.qrid
                          }
                        />
                      </p>
                    </div>
                  </div>
                </>
              )}

              <br />

              {event.active === 1 && (
                <button
                  className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-4"
                  style={{
                    fontSize: "16px",
                    width: "18rem",
                    marginTop: "2rem",
                  }}
                  onClick={() => navigate(`/events/email/${localPinID}`)}
                  type="button"
                >
                  Send PiNs
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EventDetail;
