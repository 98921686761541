import { useContext, useState } from "react";
import { AlertContext } from "../../App";
import { useNavigate } from "react-router";
import AlertPopup from "../Alert/alert";

const ChangePassword = ({
  PasswordValidation,
  handlePasswordChange,
  handleConfirmpasswordChange,
}) => {
  const alertContext = useContext(AlertContext);
  const navigate = useNavigate();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [error, setError] = useState();

  const changePassword = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return navigate("/");
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const res = await fetch(
        process.env.REACT_APP_BASEURL + "/members/changepass",
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${user.sessionToken}`,
            "content-type": "application/json",
          },
          body: JSON.stringify({
            password,
          }),
        }
      );
      const data = await res.json();
      if (data?.success) {
        alertContext.show = true;
        alertContext.variant = "primary";
        alertContext.msg = "Password changed successfuly";
        localStorage.removeItem("user");
        navigate("/");
        return;
      }
    } catch (err) {
      alertContext.show = true;
      alertContext.variant = "danger";
      if (err.toString()?.includes("Forbidden")) {
        alertContext.msg = "Session expired, please sign in";
        localStorage.removeItem("user");
        navigate("/");
      } else {
        alertContext.msg = "Error resetting password";
      }
      return;
    }
  };

  return (
    <div>
      {error && (
        <AlertPopup
          Message={error}
          variant="danger"
          setShow={() => setError(null)}
        />
      )}
      <form>
        <table border="0">
          <tbody>
            <tr>
              <td>Password:</td>
              <td>
                <input
                  type="password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />{" "}
              </td>
              <td>*</td>
            </tr>
            <tr>
              <td>Password confirmation:</td>
              <td>
                <input
                  type="password"
                  required
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <br />

                <button
                  className="btn btn-primary"
                  type="button"
                  style={{ fontWeight: 500 }}
                  onClick={() => changePassword()}
                >
                  Update
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default ChangePassword;
