import { useEffect, useState } from "react";
import "./dashboard.css";
import { Outlet, useNavigate } from "react-router";

function Dashboard({ login }) {
  const [eventData, setEventData] = useState({
    total: 0,
    active: 0,
  });
  const [Accinfo, setAccInfo] = useState({
    issued: 0,
    waitlist: 0,
    purchased: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return navigate("/");
    }

    const getData = async () => {
      const res = await fetch(
        process.env.REACT_APP_BASEURL + "/members/dashboardcounts",
        {
          headers: {
            Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );
      const data = await res.json();
      console.log(data);

      setEventData(data?.pins);
    };

    getData();
  }, [navigate]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
    if (!user) {
      return navigate("/");
    }
    const getBalance = async () => {
      const response = await fetch(
        process.env.REACT_APP_BASEURL + "/members/profile/balance",
        {
          headers: {
            Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );
      const data1 = await response.json();
      // console.log(data1);
      const acin = data1;
      if (data1?.issues && data1?.waitlist) {
      }
      setAccInfo(acin);
    };

    getBalance();
  }, [navigate]);

  // Function to handle the click
  const handleTotalEvents = () => {
    if (eventData.total === 0) {
      // If the user has 0 events, prompt them to create one
      const createEvent = window.confirm(
        "You have no events yet. Would you like to create one?"
      );

      // If user clicks ok, handle event create
      if (createEvent) {
        navigate("/newevent");
      }
    } else {
      // Handle case where the user has events
      navigate("/events");
    }
  };

  const handleNavigation = (page) => navigate(`${page}`);
  return (
    <>
      <div>
        <h1>Dashboard</h1>
        <div class="row">
          <div class="col-sm-4 mb-3 mb-sm-4">
            <div
              className="card"
              // onClick={() => handleNavigation("/events")}
              onClick={handleTotalEvents}
              style={{ width: "18rem", marginBottom: "1.5rem" }}
            >
              <div className="card-body">
                <h5 className="card-title" style={{ marginRight: "0.2rem" }}>
                  Total Events
                </h5>
                <p
                  className="card-text"
                  title="Total number of events"
                  data-toggle="tooltip"
                  // onClick={handleTotalEvents}
                >
                  {eventData.total}
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mb-3 mb-sm-4">
            <div
              className="card"
              style={{ width: "18rem", marginBottom: "1.5rem" }}
              onClick={() => handleNavigation("/events/active")}
            >
              <div className="card-body">
                <h5 className="card-title" style={{ marginRight: "0.2rem" }}>
                  Active Events
                </h5>
                <p
                  className="card-text"
                  title="Number of active events"
                  data-toggle="tooltip"
                >
                  {eventData.active}
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mb-3 mb-sm-4">
            <div
              className="card"
              style={{ width: "18rem", marginBottom: "1.5rem" }}
              onClick={() => handleNavigation("/billing")}
            >
              <div className="card-body">
                <h5 className="card-title" style={{ marginRight: "0.2rem" }}>
                  Account Balance
                </h5>
                <p
                  className="card-text"
                  title="PiNs available to issue"
                  data-toggle="tooltip"
                >
                  {Accinfo.purchased - (Accinfo.issued + Accinfo.waitlist)}
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mb-3 mb-sm-4">
            <div
              className="card"
              style={{ width: "18rem", marginBottom: "1.5rem" }}
            >
              <div className="card-body">
                <h5 className="card-title" style={{ marginRight: "0.2rem" }}>
                  Claimed PiNs
                </h5>
                <p
                  className="card-text"
                  title="Number of PiNs claimed"
                  data-toggle="tooltip"
                >
                  {Accinfo.issued}
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mb-3 mb-sm-4">
            <div
              className="card"
              style={{ width: "18rem", marginBottom: "1.5rem" }}
              onClick={() => handleNavigation("/waitlist")}
            >
              <div className="card-body">
                <h5 className="card-title" style={{ marginRight: "0.2rem" }}>
                  Unclaimed PiNs
                </h5>
                <p
                  className="card-text"
                  title="Unclaimed PiNs emailed to attendees"
                  data-toggle="tooltip"
                >
                  {Accinfo.waitlist}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default Dashboard;
