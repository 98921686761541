import { useState } from "react";
import "./collection.css";
import AlertPopup from "../Alert/alert";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const Collection = () => {
  const [name, setname] = useState();
  const [pinscount, setpinscount] = useState();
  const [collectionissued, setcollectionissued] = useState();
  const [error, seterror] = useState();
  const navigate = useNavigate();

  const handlecreate = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (pinscount < 2 || pinscount > 100) {
      seterror("Please enter valid pin");
    } else if (collectionissued < 1 || collectionissued > 1000) {
      seterror("Please enter valid collection");
    } else {
      const response = await fetch(
        process.env.REACT_APP_BASEURL + "/members/collections",
        {
          method: "post",
          body: JSON.stringify({
            name,
            maxtokens: collectionissued,
            totalpins: pinscount,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );
      console.log(response);
      const data = await response.json();
      console.log(data);
      navigate("/events");
    }
  };
  const handleErrorpopup = () => {
    seterror(null);
  };
  return (
    <div className="mx-auto collection" style={{ padding: "0 2rem" }}>
      {error && (
        <AlertPopup
          message={error}
          variant="danger"
          setShow={handleErrorpopup}
        />
      )}
      <Link
        to="/dashboard/collectionlisting"
        style={{ textDecoration: "none" }}
      >
        {" "}
        &larr; Back to collections
      </Link>
      <h3 className="login-heading mb-4">New Collections</h3>
      <form>
        <div className="form-floatin mb-3">
          <input
            type="text"
            className="form-control"
            name="name"
            onChange={(e) => setname(e.target.value)}
            autoComplete="off"
            max={100}
            maxLength={100}
          />
          <label htmlFor="name"> Name</label>
        </div>
        <div className="form-floatin mb-3">
          <input
            type="text"
            className="form-control"
            name="pins count"
            onChange={(e) => setpinscount(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="pins count">PiNs count</label>
        </div>
        <div className="form-floatin mb-3">
          <input
            type="text"
            className="form-control"
            name="collection issued"
            onChange={(e) => setcollectionissued(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="collection issued">Collection issued count</label>
        </div>
        <div className="form-floating mb-3"></div>
        <div className="form-floating mb-3">
          <button
            className="btn btn-lg btn-primary btn-login text-uppercase fw-bold"
            style={{ fontSize: "16px" }}
            onClick={handlecreate}
            type="button"
          >
            Create
          </button>
        </div>
      </form>
    </div>
  );
};

export default Collection;
