import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router";
import { AlertContext } from "../../App";

const TotalBilling = () => {
  const [Billing, setBilling] = useState();

  const alertContext = useContext(AlertContext);

  useEffect(() => {
    const getBilling = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
        return Navigate("/");
      }
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASEURL}/members/billing`,
          {
            headers: {
              Authorization: `Bearer ${user.sessionToken}`,
            },
          }
        );
        const data = await res.json();
        setBilling(data);
        console.log(data);
      } catch (err) {
        alertContext.show = true;
        alertContext.variant = "danger";
        if (err.toString()?.includes("Forbidden")) {
          alertContext.msg = "Session expired, please sign in";
          localStorage.removeItem("user");
          Navigate("/");
        } else {
          alertContext.msg = "Error getting PiNs";
        }
      }
    };

    getBilling();
  }, [alertContext]);


  const formatDate = (date) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    return formattedDate;
  };
  return (
    <>
      <div className="container">
        <br />
        <h2>Billing</h2>
        <div className="row">
          <div className="col-2">
            <b>Date</b>
          </div>
          <div className="col">
            <b>PiNs Type</b>
          </div>
          <div className="col">
            <b>Transaction Type</b>
          </div>
          <div className="col">
            <b>PiNs Purchased</b>
          </div>
        </div>

        {Billing &&
          Billing.map((data) => {
            const toTitleCase = (str) => {
              return str.replace(/\w\S*/g, (txt) => {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
              });
            };

            return (
              <div className="row" key={data.id}>
                <div className="col-2" style={{ verticalAlign: "top" }}>
                  {formatDate(data.created_at)}
                </div>
                <div className="col">
                  {data.pinstype === "DIGITAL SOUVENIR" ? "Souvenir PiNs" : "Unknown"}
                </div>
                <div className="col">
                  {data.transtype === "SIGNUP_BONUS" ? "Signup Bonus" : data.transtype}
                </div>
                <div className="col">
                  {data.tokens}
                </div>
              </div>
            );
          })}
      </div>

      <div className="container">
        <br />
        <br />
        <button className="btn btn-primary" disabled>
          Buy more PiNs
        </button>
        <br />
        <br />
        Contact <a href="mailto:billing@clifty.io">billing@clifty.io</a> to buy more PiNs.
      </div>
    </>
  );
};

export default TotalBilling;
